/* Mixins */
.things-sync-dialog .things-sync-remote-form {
  border: solid 1px #e8e8e8;
  border-top: none;
  margin-bottom: 16px;
}
.things-sync-dialog .things-sync-remote-form .ant-divider {
  top: -13px;
}
.things-sync-dialog .transform-header {
  margin-bottom: 8px;
}
.things-sync-dialog .transform-name {
  height: 100%;
}
.things-sync-dialog .transform-column, .things-sync-dialog .transform-column-middle {
  height: 50vh;
}
.things-sync-dialog .right-square-icon {
  font-size: 20px;
  color: #575757;
}
.things-sync-dialog .transform-column .ant-card-body {
  height: calc(100% - 48px);
}
.things-sync-dialog .transform-column .ant-card-body .ul-list {
  height: calc(100% - 32px);
}