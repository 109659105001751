/* Mixins */
.things-sync-preview-dialog .presync-result {
  background-color: #fafafa;
  padding: 16px 24px;
  border: 1px solid #e8e8e8;
  margin-bottom: 8px;
}
.things-sync-preview-dialog .color-green {
  color: blue;
}
.things-sync-preview-dialog .color-fail {
  color: red;
}